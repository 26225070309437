import { createFeature, createReducer, on } from '@ngrx/store';
import { WebhookChannelActions } from './webhook-channel.actions';
import { WebhookChannel } from 'src/app/shared/models';

export const webhookChannelFeatureKey = 'webhook-channel';

export interface WebhookChannelState {
  webhookChannels: WebhookChannel[];
  webhookChannel: WebhookChannel | null;
  loading: boolean;
  error: any;
}

export const initialState: WebhookChannelState = {
  webhookChannels: [],
  webhookChannel: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    WebhookChannelActions.loadWebhookChannels,
    (state): WebhookChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.loadWebhookChannelsSuccess,
    (state, { webhookChannels }): WebhookChannelState => ({
      ...state,
      webhookChannels: [
        ...state.webhookChannels.filter(
          x =>
            !webhookChannels.find(
              y => x.userId && x.userId === y.userId && x.id === y.id
            )
        ),
        ...webhookChannels,
      ],
      loading: false,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.loadWebhookChannelsFailure,
    (state, { error }): WebhookChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    WebhookChannelActions.loadWebhookChannel,
    (state): WebhookChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.loadWebhookChannelSuccess,
    (state, { webhookChannel }): WebhookChannelState => ({
      ...state,
      webhookChannels: [
        ...state.webhookChannels.filter(
          item =>
            webhookChannel.id !== item.id &&
            webhookChannel.userId !== item.userId
        ),
        webhookChannel,
      ],
      webhookChannel: webhookChannel,
      loading: false,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.loadWebhookChannelFailure,
    (state, { error }): WebhookChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    WebhookChannelActions.addWebhookChannel,
    (state): WebhookChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.addWebhookChannelSuccess,
    (state, { webhookChannel }): WebhookChannelState => ({
      ...state,

      webhookChannels: [...state.webhookChannels, webhookChannel],

      loading: false,

      error: null,
    })
  ),
  on(
    WebhookChannelActions.addWebhookChannelFailure,
    (state, { error }): WebhookChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    WebhookChannelActions.removeWebhookChannel,
    (state): WebhookChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.removeWebhookChannelSuccess,
    (state, { webhookChannelId }): WebhookChannelState => ({
      ...state,
      webhookChannels: state.webhookChannels.filter(
        webhookChannel => webhookChannel.id !== webhookChannelId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.removeWebhookChannelFailure,
    (state, { error }): WebhookChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    WebhookChannelActions.updateWebhookChannel,
    (state): WebhookChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.updateWebhookChannelSuccess,
    (state, { webhookChannel }): WebhookChannelState => ({
      ...state,
      webhookChannels: state.webhookChannels.map(item =>
        // item.id === webhookChannel.id ? webhookChannel : item
        item.id === webhookChannel.id ? { ...item, ...webhookChannel } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    WebhookChannelActions.updateWebhookChannelFailure,
    (state, { error }): WebhookChannelState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const webhookChannelFeature = createFeature({
  name: webhookChannelFeatureKey,
  reducer,
});
