import { createActionGroup, props } from '@ngrx/store';
import { WebhookChannel, WebhookChannel_Source, Type } from 'src/app/shared/models';

export const WebhookChannelActions = createActionGroup({
  source: 'WebhookChannel',
  events: {
    'Load WebhookChannels': props<{
      from?: any,
      userId: string,
      size?: any,
      term?: string,
      webhookSource?: WebhookChannel_Source,
      webhookChannelType?: Type,
    }>(),
    'Load WebhookChannels Success': props<{ webhookChannels: WebhookChannel[] }>(),
    'Load WebhookChannels Failure': props<{ error: any }>(),
    'Load WebhookChannel': props<{ webhookChannelId: string; userId: string }>(),
    'Load WebhookChannel Success': props<{ webhookChannel: WebhookChannel }>(),
    'Load WebhookChannel Failure': props<{ error: any }>(),
    'Add WebhookChannel': props<{
      userId: string;
      webhookChannel: WebhookChannel | Partial<WebhookChannel>;
    }>(),
    'Add WebhookChannel Success': props<{ webhookChannel: WebhookChannel }>(),
    'Add WebhookChannel Failure': props<{ error: any }>(),
    'Remove WebhookChannel': props<{ webhookChannelId: string; userId: string }>(),
    'Remove WebhookChannel Success': props<{ webhookChannelId: string }>(),
    'Remove WebhookChannel Failure': props<{ error: any }>(),
    'Update WebhookChannel': props<{
      userId: string;
      webhookChannelId: string;
      webhookChannel: Partial<WebhookChannel>;
    }>(),
    'Update WebhookChannel Success': props<{
      userId: string;
      webhookChannelId: string;
      webhookChannel: Partial<WebhookChannel>;
    }>(),
    'Update WebhookChannel Failure': props<{ error: any }>(),
  },
});
